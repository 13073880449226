.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 500px;
  background-color: rgba(220, 235, 245, 0.2) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  &::before {
    content: "";
    display: block;
    padding-top: 35%;
  }
}

.hero-content {
  max-width: 1000px;
  padding: 0 20px;
  text-align: left;
  .hero-title {
    margin: 0;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.3;
    color: #fff;
    text-shadow: 0px 1px 1px #000;
  }
  .hero-description {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 1.7;
    color: #fff;
    text-shadow: 0px 1px 1px #000;   
  }
}

@media screen and (max-width: 720px) {
  .hero {
    min-height: 420px;
  }
  .hero-content {
    .hero-title {
      font-size: 40px;
    }
    .hero-description {
      font-size: 21px;
    }
  }
}