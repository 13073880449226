/**
 * Site header
 */
 .wrapper .container {
    max-width: 120rem;
}

.site-header {
    text-align: center;
}

.navbar {
    display: block;
}

.nav-item {
    display: inline-block;
    padding: 1rem;
}

.post-nav {
    text-align: center;
    padding: 1rem;
}

.post-title {
    display: block;
    text-align: center;
}

/**
 * override column size for mobile
 */
@media (max-width: 40.0rem) {
    .row .column.column-33, .row .column.column-34 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid #eee;
    margin: 2rem 0;
    padding: 2rem 0;
}

.contact-list,
.social-media-list {
    list-style: none;
}

.footer-text {
    font-size: 16px;
    color: #0d0d0d;
}

.footer-header {
    font-size: 24px;
    line-height: 2;
    font-weight: 400;
    color: #0d0d0d;
}

/**
 * Misc
 */
.preview-panel {
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #eee;
    
    &:hover {
        border: 1px solid #eee;
    }
}
