.catalogue {
  &-item {
    color: $default-color;
    display: inline-block;
    padding: 1rem 0;

    &:hover .catalogue-line,
    &:focus .catalogue-line {
      width: 5rem;
    }

    &:last-child {
      border: 0;
    }
  }

  &-time {
    color: $default-tint;
    font-family: "Source Sans Pro", Arial, sans-serif;
    letter-spacing: .5px;
  }

  &-title {
    color: #4183C4;
    display: block;
    font-family: "Source Sans Pro", Arial, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    margin: .5rem 0;
  }

  &-base {
    border-bottom: 1px solid $grey-2;
    padding: 1rem 0;
  }
}

// from the second theme

.post {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 11rem;
  border-radius: 10px;
  overflow: hidden;
  transition: all .3s ease;
  box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
  &:hover {
    transform: translate(0px, -2px);
    box-shadow: 0 15px 45px -10px rgba(10, 16, 34, .2);
  }

  .post-thumbnail {
    width: 30%;
    max-width: 100%;
    min-height: 11rem;
    background-size: cover;
    background-position: 50% 50%;
  }

  .post-content {
    padding: 1rem;
    width: 70%;
    .post-date,
    .post-words {
      font-size: 12px;
    }
    .post-title {
      margin: 0 0 10px;
      font-size: 30px;
      font-weight: 400;
      a {
        text-decoration: none;
      }
    }
    p {
      margin-top: 0;
    }
  }
}

.post {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  min-height: 11rem;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-shadow: 0 1px 1px 0 rgba(31, 35, 46, 0.15);
          box-shadow: 0 1px 1px 0 rgba(31, 35, 46, 0.15);
}

.post:hover {
  -webkit-transform: translate(0px, -2px);
      -ms-transform: translate(0px, -2px);
          transform: translate(0px, -2px);
  -webkit-box-shadow: 0 15px 45px -10px rgba(10, 16, 34, 0.2);
          box-shadow: 0 15px 45px -10px rgba(10, 16, 34, 0.2);
}

.post .post-thumbnail {
  width: 30%;
  max-width: 100%;
  min-height: 11rem;
  -webkit-background-size: cover;
          background-size: cover;
  background-position: 50% 50%;
}

.post .post-content {
  padding: 1rem;
  width: 70%;
}

.post .post-content .post-date,
.post .post-content .post-words {
  font-size: 12px;
}

.post .post-content .post-title {
  margin: 0 0 10px;
  font-size: 30px;
  font-weight: 400;
}

.post .post-content .post-title a {
  text-decoration: none;
}

.post .post-content p {
  margin-top: 0;
}

/* - - - - - - - - - - Media Styles - - - - - - - - - - */
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .post {
    width: 48%;
    max-width: 100%;
    margin: 0 0.9% 1.5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .post .post-thumbnail {
    width: 100%;
  }
  .post .post-content {
    width: 100%;
  }
  .post .post-content .post-title {
    margin: 0 0 5px;
  }
  .post .post-content .post-title a {
    font-size: 21px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .post {
    width: 98%;
    max-width: 100%;
    margin: 2%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .post .post-thumbnail {
    width: 100%;
  }
  .post .post-content {
    width: 100%;
  }
  .post .post-content .post-title {
    margin: 0 0 5px;
  }
  .post .post-content .post-title a {
    font-size: 21px;
  }
}

.post h2.headline {
  /*font: italic 22px/1.3em Georgia,serif;*/
  font: normal 13px/1.5em "Source Sans Pro",Helvetica,Arial,sans-serif;
  margin: -5px 0 40px 0;
  color: #b2b9be;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    /*margin-top: 15px;*/
    display: inline-block;
}

