@charset "utf-8";

@import
        "layout",
        "hero",
        "variables",
        "fonts",
        "catalogue",
        "pagination",
        "reset",
        "highlights"
;

//* BASIC FORMATTING *//

html {
  font-size: 100%;
}

body {
  background: $white;
  font: 22px/1.8 $helvetica;
  color: $black;
}

.container {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 10px;
  width: 100%;
}

p {
  margin: 15px 0;
}

a {
  color: #006699;
  text-decoration: none;
  font-weight: normal;
}

a:hover {
  color: #006699;
  text-decoration: underline;
  font-weight: 600;
}

li {
    font-size: 22px;
    line-height: 1.8;
    color: #0d0d0d;
    margin-left: 30px;
    list-style-type: none;
}

li:before {
    content: "\f105";
    font-family: "FontAwesome";
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: bold;
}

.small {
  font-size: 18px;
}

.breadcrumb {
  font-size: 16px;
}


.center {
  text-align: center;
}

.caption-center {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

blockquote {
    margin: 1.8em .8em;
    border-left: 2px solid $gray;
    padding: 0.1em 1em;
    color: #0d0d0d;
    font-size: 26px;
    font-style: italic;
  }

.quote {
  text-align: center;
  font-size: 28px;
  font-style: italic;
}

.quote-text {
  text-align: right;
}

.smallquote {
  font-size: 24px;
  font-style: italic;
  margin-left: 20px;
  margin-right: 20px;
}

.welcome {
  color: #C25100;
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
}

.headline {
  font-size: 28px;
  font-style: italic;
  text-align: center;
}

//* HEADERS *//

h1, h2, h3, h4, h5, h6 {
  font-family: $helveticaNeue;

  @include mobile {
    line-height: 1.4;
  }
}

h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

h2 {
  font-size: 30px;
  text-align: center;
  font-weight: 400;
}

h3 {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 24px;
  font-weight: 600;
}


//* LOGO *//

.site-title {
  font-family: 'Lato',sans-serif;
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 700;
  font-style: normal;
  font-size: 50px;
}

.site-description {
  font-family: 'Lato',sans-serif;
  line-height: 36px;
  font-weight: 400;
  font-size: 24px;
}

//* NAVIGATION *//

.navmain {
  font-size: 20px;

  a {
    color: #005e62;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 10px;
    padding: 10px 5px;
  }

}


//* FOOTER *//

footer {
  height: 35px;
  padding: 5px;
  width: 100%;
  color: $black;
  font-size: 14px;
  text-align: center;

    @include mobile {
      height: 75px;
    }

.footer-link {
  color: #006699;
  text-decoration: none;
}

.footer-link:hover {
  color: #006699;
  text-decoration: underline;
}

.footer-link:active {
  color: #006699;
  text-decoration: underline;
}

.footer-link:visited {
  color: #006699; 
}

}


//* IMAGES *//

img {
  max-width: 100%;
}

.image-right {
  float: right;
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 7px;
  height: 220px;
  width: 220px;
}

.image-right-large {
  float: right;
  margin-right: 7px;
  margin-left: 7px;
  margin-top: 7px;
}

.image-left {
  float: left;
  margin-right: 14px;
  margin-left: 7px;
  margin-top: 7px;
  height: 220px;
  width: 220px;
}

.image-left-large {
  float: left;
  margin-right: 14px;
  margin-left: 7px;
  margin-top: 7px;
}

.large img, .large.embed {
  max-width: 140%;
  margin-right: -20%;
  margin-left: -20%;
  }

.image-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.center-text {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
}

.center-small {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

//* COLUMNS *//

.column {
  float: left;
  width: 50%;
  padding-right: 10px;
  font-size: 18px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

.column2 {
  float: left;
  width: 25%;
  padding-right: 10px;
  font-size: 18px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column2 {
    width: 100%;
  }
}

.column3 {
  float: left;
  width: 33.33%;
  padding-right: 10px;
  font-size: 18px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column3 {
    width: 100%;
  }
}



//* PAGINATION *//

.pagination {
  padding-bottom: 20px;
  line-height: 22px;
  font-size: 18px;
  color: #0d0d0d;
}

.pagination .arrow {
  color: #787121;
}

.pagination:hover .arrow:hover {
  color: #787121;
  text-decoration: underline;
}

// Nav

.gray {
    border-radius: 2px;
    padding: 0.4em 0.8em;
    background: #eee;
    transition: background, 0.2s;
    font-size: 20px;
    line-height: 1.8;
    text-align: center;
}

.navigation {
  text-align: center;
}

//* ALIGNMENT *//

.align-right {
  text-align: right;
  font-size: 16px;
}

//* POST *//

.post-title {
  font-size: 40px;
  font-weight: 600;
}

.post-details {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

//* BLOG *//

.blog {
  font-size: 32px;
  text-align: center;
}

.category {
  font-size: 24px;
  text-align: center;
}

.post-tag {
  font-size: 18px;
}

.tiny {
  font-size: 16px;
  text-align: center;
}
